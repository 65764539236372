import { defineStore } from "pinia";
import objectHash from "object-hash";
import { CustomMetricPreset } from "~/types/MetricPreset";
import {
  AdMetricsBreakdown,
  AdMetricsBreakdownResponse,
  Breakdown,
  Chart,
  ColumnExpression,
  CustomMetricRuleResponse,
  CustomMetricSort,
  GetAdMetricsBreakdownDto,
  GetInsightsBatchDto,
  GetInsightsDto,
  GetVideoPlayCurveActionsDto,
  Stat,
  ValueFormatter,
} from "~/types/Metrics";
import { AttributionWindow, Provider, Sort, SortBy } from "~/types/shared";

export const useMetricsStore = defineStore({
  id: "metrics",
  state: () => {
    return {
      presets: [],
      insights: {},
      breakdowns: {},
      videoPlayCurveActions: {},
    } as {
      presets: Array<CustomMetricPreset>;
      insights: {
        [hash: string]: Stat | Breakdown<number | string> | Chart;
      };
      breakdowns: {
        [hash: string]: AdMetricsBreakdown[];
      };
      videoPlayCurveActions: {
        [hash: string]: number[];
      };
    };
  },
  actions: {
    async createMetricPreset(input: {
      title: string;
      primaryMetric: string;
      secondaryMetrics: Array<string>;
      sort: Sort;
      sortBy: SortBy;
      creativeSortBy?: SortBy | null;
      creativeSort?: Sort | null;
      clientId: number;
      provider: Provider;
      attributionWindow: AttributionWindow;
    }) {
      const { error, data } = await useDatAdsApiFetch<{
        data: { preset: CustomMetricPreset };
      }>("metrics/preset", {
        method: "POST",
        body: input,
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      if (data.value) {
        this.presets.push(data.value.data.preset);
        return data.value.data.preset;
      }
      return null;
    },

    async duplicateMetricPreset(dto: { presetId: number; clientId: number }) {
      const { error, data } = await useDatAdsApiFetch<{
        data: { preset: CustomMetricPreset };
      }>(`metrics/preset/duplicate`, {
        method: "POST",
        body: dto,
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      if (data.value) {
        this.presets.push(data.value.data.preset);
        return data.value.data.preset;
      }
      return null;
    },

    async updateMetricPreset(input: {
      presetId: number;
      title?: string;
      primaryMetric?: string;
      secondaryMetrics?: Array<string>;
      sort?: Sort;
      sortBy?: SortBy;
      creativeSortBy?: SortBy | null;
      creativeSort?: Sort | null;
      attributionWindow?: AttributionWindow;
      applyAll?: boolean;
    }) {
      const { error } = await useDatAdsApiFetch(
        `metrics/preset/${input.presetId}`,
        {
          method: "PATCH",
          body: {
            title: input.title,
            primaryMetric: input.primaryMetric,
            secondaryMetrics: input.secondaryMetrics,
            sort: input.sort,
            attributionWindow: input.attributionWindow,
            sortBy: input.sortBy,
            creativeSortBy: input.creativeSortBy,
            creativeSort: input.creativeSort,
            applyAll: input.applyAll,
          },
        },
      );
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      const index = this.presets.findIndex((a) => a.id === input.presetId);
      if (index !== -1) {
        this.presets[index] = {
          ...this.presets[index],
          ...input,
        };
      }
      return this.presets;
    },

    async deleteMetricPreset(presetId: number) {
      const { error } = await useDatAdsApiFetch(`metrics/preset/${presetId}`, {
        method: "DELETE",
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      this.presets = this.presets.filter((a) => a.id !== presetId);
      return null;
    },

    async listMetricPresets() {
      const { error, data } = await useDatAdsApiFetch<{
        data: { presets: Array<CustomMetricPreset> };
      }>("metrics/preset");
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      if (data.value) {
        this.presets = data.value.data.presets;
      }
      return this.presets;
    },

    async createCustomMetricRule(input: {
      title: string;
      description: string | null;
      expression: ColumnExpression;
      formatter: ValueFormatter;
      sort: CustomMetricSort;
      clientId: number;
      provider: Provider;
    }) {
      const { error, data } = await useDatAdsApiFetch<{
        data: { rule: CustomMetricRuleResponse };
      }>("metrics/custom-metric-rule", {
        method: "POST",
        body: input,
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      if (data.value) {
        const { getMappedCustomMetricRules } = useMetrics();
        return getMappedCustomMetricRules([data.value.data.rule])[0];
      }
      return null;
    },

    async updateCustomMetricRule(input: {
      ruleId: number;
      title?: string;
      description?: string;
      expression?: ColumnExpression;
      formatter?: ValueFormatter;
      sort?: CustomMetricSort;
    }) {
      const { error } = await useDatAdsApiFetch(
        `metrics/custom-metric-rule/${input.ruleId}`,
        {
          method: "PATCH",
          body: {
            ...input,
            ruleId: undefined,
          },
        },
      );
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      return null;
    },

    async deleteCustomMetricRule(ruleId: number) {
      const { error } = await useDatAdsApiFetch(
        `metrics/custom-metric-rule/${ruleId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      return null;
    },

    async getInsightsBatch(input: GetInsightsBatchDto) {
      const isCached = (dto: GetInsightsDto) => {
        const hash = objectHash(dto);
        const cachedInsight = this.insights[hash];
        if (cachedInsight != null) return true;
        return false;
      };

      const getResults = () =>
        input.requests.map((a) => {
          const hash = objectHash(a);
          return this.insights[hash];
        });

      const needFetch = input.requests.filter((a) => !isCached(a));

      if (needFetch.length === 0) return getResults();

      const { error, data } = await useDatAdsApiFetch<{
        data: {
          results: Array<{
            id: string;
            insights: Breakdown<string | number> | Chart | Stat;
            accumulatedInsights?: Stat;
          }>;
        };
      }>("metrics/insights/batch", {
        method: "POST",
        body: {
          requests: needFetch,
        },
      });

      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }

      if (data.value) {
        data.value.data.results.forEach((item) => {
          const dto = needFetch.find((a) => a.id === item.id);
          if (dto != null) {
            const hash = objectHash(dto);
            this.insights[hash] = item.insights;
          }
        });

        return getResults();
      }

      return null;
    },

    async getAdMetricsBreakdown(dto: GetAdMetricsBreakdownDto) {
      const hash = objectHash(dto);

      if (this.breakdowns[hash] != null) {
        return this.breakdowns[hash];
      }

      const { error, data } = await useDatAdsApiFetch<{
        data: { data: AdMetricsBreakdownResponse[] };
      }>("metrics/insights/ad/breakdown", {
        method: "POST",
        body: {
          ...dto,
          startDate: dto.startDate ? dto.startDate.split("T")[0] : null,
          endDate: dto.endDate ? dto.endDate.split("T")[0] : null,
        },
      });

      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }

      if (data.value) {
        this.breakdowns[hash] = data.value.data.data;
        return data.value.data.data;
      }

      return [];
    },

    async fetchVideoPlayCurveActions(dto: GetVideoPlayCurveActionsDto) {
      const hash = objectHash(dto);

      if (this.videoPlayCurveActions[hash] != null) {
        return this.videoPlayCurveActions[hash];
      }

      const { error, data } = await useDatAdsApiFetch<{
        data: { playCurveActions: number[] };
      }>("metrics/insights/video-play-curve-actions", {
        method: "POST",
        body: {
          ...dto,
          startDate: dto.startDate ? dto.startDate.split("T")[0] : null,
          endDate: dto.endDate ? dto.endDate.split("T")[0] : null,
        },
      });

      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }

      if (data.value) {
        this.videoPlayCurveActions[hash] = data.value.data.playCurveActions;
        return data.value.data.playCurveActions;
      }

      return [];
    },

    removeCustomMetricFromPresets(metricName: string) {
      for (let idx = 0; idx < this.presets.length; idx++) {
        const preset = { ...this.presets[idx] };
        preset.secondaryMetrics = preset.secondaryMetrics.filter(
          (a) => a !== metricName,
        );
        if (preset.primaryMetric === metricName) {
          const { getProviderDefaultMetric } = useMetrics();
          preset.primaryMetric = getProviderDefaultMetric(preset.provider);
        }
        // Trigger reactivity to update metric modals
        this.presets.splice(idx, 1, { ...preset });
      }
    },

    renameCustomMetricInPresets(dto: { oldName: string; newName: string }) {
      const { oldName, newName } = dto;
      for (let idx = 0; idx < this.presets.length; idx++) {
        const preset = { ...this.presets[idx] };
        if (preset.primaryMetric === oldName) {
          preset.primaryMetric = newName;
        }
        preset.secondaryMetrics = preset.secondaryMetrics.map((a) =>
          a === oldName ? newName : a,
        );
        // Trigger reactivity to update metric modals
        this.presets.splice(idx, 1, { ...preset });
      }
    },
  },
  getters: {
    getMetricPresets: (state) => (clientId: number, provider: Provider) => {
      return state.presets.filter(
        (a) => a.clientId === clientId && a.provider === provider,
      );
    },

    getMetricPresetById: (state) => (presetId: number) => {
      return state.presets.find((a) => a.id === presetId) ?? null;
    },

    getBreakdowns: (state) => (dto: GetAdMetricsBreakdownDto) => {
      const hash = objectHash(dto);
      return Array.isArray(state.breakdowns[hash])
        ? [...state.breakdowns[hash]]
        : [];
    },

    getVideoPlayCurveActions: (state) => (dto: GetVideoPlayCurveActionsDto) => {
      const hash = objectHash(dto);
      return Array.isArray(state.videoPlayCurveActions[hash])
        ? [...state.videoPlayCurveActions[hash]]
        : [];
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMetricsStore, import.meta.hot));
}
